.header__primary {
	background-color: #fff;
	/*
	border-bottom: .125em solid #dee2e4;
	*/
	min-width: 100%;
	position: relative;
	/*
	@media (--med) {
		position: sticky;
		top: 0;
		z-index: 20;
	}
	*/
}
