.breadcrumbs {
	background-color: rgba(255, 255, 255, .35);
	border-top: .125em solid #dee2e4;
	clear: both;
	display: block;
	font-size: .8rem;
	min-width: 100%;

	&[hidden] {
		display: none;
	}

	ul {
		background-color: rgba(245, 245, 245, .8);
		list-style: none;
		padding: .6em;
	}

	li {
		color: #000;
		display: inline;
		padding: 0 .25rem;
		/*
		&:not(:last-child):after {
			content: "\00a0\00bb";
		}
		*/
		/*
		&:not(:last-child) a:after {
			color: #333;
			content: "\00a0\00bb";
			margin-left: .5em;
		}
		*/
	}

	.sep {
		padding-left: .5em;
	}

	a,
	li {
		font-weight: 600;
	}

	a {
		border: solid transparent;
		border-width: 0 0 .0625rem 0;
		color: var(--color-links);
		/*
		padding-right: .25rem;
		*/

		&:hover {
			border-color: inherit;
		}

		&:focus {
			border: 1px dashed #999;
		}
	}

}
