.nav {

	&_primary {

		align-items: center;
		align-content: center;
		background-color: #fff;
		display: flex;
		flex-direction: row;
		padding: .5em 1em .25em;
		position: relative;

		.topnav {
			background: #fff;
			margin-top: 0;
			opacity: 1;
			position: absolute;
			right: 0;
			top: 3.25em;
			transition: transform .2s ease-out 0s, opacity .2s ease-out .1s;
			width: 100%;
			z-index: 3;

			> li {
				position: relative;

				> a {
					border: solid #ddd;
					border-width: 0 0 1px 0;
					font-size: 1.1rem;
					font-weight: 600;

					&[aria-current="page"] {
						color: #000;
					}

					@media (--lg) {
						border: solid #fff;
						border-width: .125em;
						border-radius: .25em;
						font-size: 1rem;
					}
				}
			}

			&[hidden] {
				opacity: 0;
				top: -999em;
				transform: translateX(0);
				transition: transform .3s ease-out 0s, opacity .15s ease-out 0s;
			}

			@media (--lg) {
				background: #fff;
				margin-left: auto;
				position: relative;
				right: unset;
				top: unset;
				transform: unset;
				transition: unset;
				width: unset;

				&[hidden] {
					opacity: 1;
					top: unset;
					transform: unset;
					transition: unset;
					display: flex;
				}
			}

		}

		ul {
			background: #fff;
			list-style: none;
		}

		a {
			color: var(--color-navlinks);
			display: block;
			line-height: 1;
			padding: 1rem;
			text-decoration: none;
			transition: all .25s;
			white-space: nowrap;

			&:focus,
			&:hover {
				color: #000;
			}

			&[aria-current="true"] {
				color: #000;
				font-weight: 600;
			}

			&[aria-haspopup="true"] {

				&:after {
					color: #ccc;
					content: "\0020\25BE";
				}

				/*
				&:focus,
				&:hover {
					&:after {
						color: var(--mast2);
					}
				}
				*/
			}

			&.logo {
				color: var(--mast1);
				display: inline-block;
				font-size: 2.4rem;
				font-family: var(--font-logo);
				letter-spacing: -.0625em;;
				line-height: 1;
				padding: .0625rem .125rem;;
				text-decoration: none;
				transition: unset;

				&:focus {
					border: .0625rem dashed #555;
				}

				span {
					color: var(--mast2);
					display: inline-block;
				}

				&:hover {
					color: var(--mast2);
					span {
						color: var(--mast1);
					}
				}

				@media (--lg) {
					font-size: 2.4rem;
				}
			}
		}

		li {
			padding: 0 .5rem;

		}

		[hidden] {
			display: none;
		}

		@media (--lg) {
			margin: 0 auto;
			max-width: var(--widthXL);

			> ul {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				/*
				margin-right: 5vw;
				*/

				> li:last-child .sub {
					right: -.5em;
				}

				a {
					&:hover,
					&:focus {
						border-color: color(var(--mast1) l(80%));
					}
				}
			}
		}

		.sub {
			margin: 0;
			transition: opacity 300ms;

			&[aria-hidden="true"] {
				opacity: 0;
				position: absolute;
				top: -999em;
			}

			li {
				padding: 0;
			}

			a {
				border-radius: unset;
				padding-left: 2em;

				&[aria-current="page"] {
					background: var(--mast2);
					color: #fff;
				}

			}

			@media (--lg) {
				box-shadow: 0 0 .5em #ccc;
				overflow: hidden;
				position: absolute;
				top: 3.625em;
				transition: background-color, color 300ms;
				min-width: 100%;

				a {
					border: unset;
					color: #333;
					display: block;
					padding: 1em;

					&:focus,
					&:hover {
						background: var(--mast2);
						color: #fff;
					}
				}

				li {
					max-width: 20rem;
				}
			}
		}
	}

	/**
	 * hamburger button
	 */
	&_toggle {
		background: #fff;
		/*
		border: 1px solid #ccc;
		*/
		cursor: pointer;
		display: block;
		/*
		padding: .0625rem;
		*/
		height: 1.5rem;
		margin-top: 0;
		padding: 0;
		position: absolute;
		right: 3.5rem;
		top: 1rem;
		/*
		width: 2rem;
		*/
		&:focus + .topnav {
			top: 3rem;
			left: 0;
		}

		svg {
			fill: none;
			position: relative;
			stroke: var(--mast1);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3;

			line {
				transition: all .25s;
			}
		}

		&[aria-expanded="true"] {

			line {
				stroke: var(--mast2);
				/*
				transform: rotate(45deg) translate(1px, -4px);
				*/
				transform: rotate(45deg) translate(.0625rem, -.25rem);

				&.beef {
					stroke: transparent;
				}

				&:last-child {
					/*
					transform: rotate(-45deg) translate(1px, 4px);
					*/
					transform: rotate(-45deg) translate(.0625rem, .25rem);
					transform-origin: bottom left;
				}
			}
		}

		@media (--lg) {
			display: none;
		}
	}
}

.a11y {
	&_skip {
		clip: rect(1px 1px 1px 1px);
		height: 1px;
		overflow: hidden;
		position: absolute;
		transform: translateY(-100%);
		transition: transform .5s cubic-bezier(.18, .89, .32, 1.28);
		white-space: nowrap;
		width: 1px;
		&:focus {
			/*
			background-color: var(--color-dkblue-sm);
			*/
			background-color: var(--mast2);
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			clip: auto;
			color: #fff;
			font-size: 1em;
			height: auto;
			left: 50%;
			margin: 0 0 0 -100px;
			outline: 0;
			overflow: visible;
			padding: .8em 0 .7em;
			position: fixed;
			text-align: center;
			text-decoration: none;
			top: -.3em;
			transform: translateY(0%);
			white-space: normal;
			width: 200px;
			z-index: 4;
		}
	}
}
