main {
	flex: 1 0 auto;
	margin: 0 auto;
	width: 100%;

	p,
	li {
		line-height: 1.8;
		max-width: 50em;
	}

	.intro {

		p {
			color: #444;
			font-size: 1.1em;
			margin: 1rem 0 .5rem;

			@media (--lg) {
				font-size: 1.5em;
				/*
				max-width: 45rem;
				*/
			}
		}

		img {
			border-radius: 50%;
			shape-outside: circle();
			shape-margin: 3px;
		}
	}

	@media (--lg) {
		flex-direction: row;
		background-image: linear-gradient(180deg, rgba(255, 255, 255, .25), rgba(255, 255, 255, 0));
		/*
		background-image: linear-gradient(180deg, var(--mast2), #fff);
		background: var(--mast2) url("../s/triangles.svg") center center fixed;
		background-size: cover;
		*/
		/*
		background-repeat: no-repeat;
		*/

	}

	/*
	@media (--xl) {
		background-image: linear-gradient(180deg, var(--mast2), #fff);
	}
	*/

	a {
		text-decoration: none;
		transition: all .2s ease-in-out;
		/*
		border-bottom: 1px solid currentColor;
		*/
		border-bottom: 1px solid #ccc;;

		&:active,
		&:focus,
		&:hover {
			border-color: var(--color-hlinks);
		}

		&[href$=".docx"],
		&[href$=".doc"],
		&[href$=".pptx"],
		&[href$=".ppt"],
		&[href$=".pdf"] {
			&:after {
				color: #444;
				font-size: .8rem;
				/*
				&:hover {
					color: var(--color-a11y-red);
					color: currentColor;
				}
				*/
			}
		}

		&[href$=".pdf"] {
			&:after {
				content: "\0020[pdf]";
			}
		}

		&[href$=".pptx"],
		&[href$=".ppt"] {
			&:after {
				content: "\0020[ppt(x)]";
			}
		}

		&[href$=".docx"],
		&[href$=".doc"] {
			&:after {
				content: "\0020[doc(x)]";
			}
		}
	}

}

.full {
	/*
	padding-top: .5em !important;
	*/
	width: 100%;
}

.nopad {
	padding: 0 !important;
	width: 100%;
}

.cont {
	align-content: flex-start;
	align-items: stretch;
	background: rgba(255, 255, 255, .25);
	color: #222;
	display: flex;
	flex: 1;
	flex-direction: column;
	min-height: 75vh;
	justify-content: flex-start;
	margin: 0 auto;
	max-width: var(--widthXL);
	position: relative;
	z-index: 2;

	&.wide {
		max-width: 100% !important;

		/*
		.cont_article {
			background: transparent;
		}
		*/
	}

	a {
		/*
		font-weight: 500;
		*/

		&:active,
		&:focus {
			border: 1px dashed #333;
		}
	}

	&_article,
	&_sidebar {
		padding-bottom: 2em;

		@media (--lg) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	&_article {
		background: #fff;
		/*
		padding-left: .5em;
		padding-right: .5em;
		padding-left: 2vw;
		padding-right: 2vw;
		padding-top: 1em;
		padding-top: 2vw;
		padding: 1em 1em 2em;
	  */
		padding: .25em 2vw 2vw;
		width: 100%;

		h1,
		h2,
		h3,
		h4 {
			color: #111;
			font-family: var(--font-lora);
			margin-top: 1em;
		}

		/*
		h4 {
			font-weight: 600;
		}
		*/

		/*
		ul[class],
		ol[class] {
			margin-bottom: 0;
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
			padding-bottom: 0;
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;
		}

		li + ol,
		li + ul,
		p + ol,
		p + ul {
			margin-top: .5em;
		}

		ul,
		ol {
			display: block;
			margin-left: .5em;
			padding: 0 1em;
		}

		ul {
			list-style: disc;
		}

		ol {
			list-style: decimal;

			&.sub__upalph ol {
				list-style: upper-alpha;
				margin: .5rem auto 1rem;
			}
		}

		li {
			padding-bottom: .25em;
		}
		*/

		ul,
		ol {
			display: block;
			margin-left: .5em;
			padding: 0 0 0 1em;
		}

		ul {
			list-style: disc;

			&.tasks {
				list-style: none;
				padding-left: 0;
				margin-left: .25em;
			}

		}

		ol {
			list-style: decimal;

			&.sub__upalph ol {
				list-style: upper-alpha;
				margin: .5rem auto 1rem;
			}
		}

		ol ul,
		ul ul {
			/*
			content: "\00BB\0020";
			*/
			margin-bottom: .25em;
			margin-top: .25em;
			list-style-type: "\00BB\0020";
		}

		/*
		&.full {
			width: 100%;
		}
		*/
	}

	/**
	 * sidebar
	 */
	&_sidebar {
		background: rgba(255, 255, 255, .5);
		border: solid rgba(80, 50, 20, .1);
		border-width: 1px;
		margin: 0 auto;
		max-width: 40em;

		a {
			border-color: transparent;
		}

		h2 {
			background: var(--mast2);
			color: #fff;
			/*
			font-family: var(--font-lora);
			*/
			font-size: 1.4em;
			/*
			font-variant: small-caps;
			*/
			margin-top: 0;
			line-height: 1.7;
			text-align: center;
			text-transform: uppercase;
		}

		&_news {
			h2 {
				text-align: center;
			}
		}

		/*
		.search {
			margin: .25rem auto 0;
			width: 100%;
		}
		*/

		@media (--med) {

			border-width: 0	0 1px 1px;
			margin-top: 0;
			position: relative;
			/*
			border-width: 1px 0 1px 1px;
			*/
			.sbar__wrap {
				margin-top: 0;
				min-width: 16rem;
				position: sticky;
				top: .5em;
			}
		}
	}

	@media (--lg) {
		background: rgba(255, 255, 255, .5);
		flex-direction: row;

		/*
		  article {
		    padding-top: 2em;
			padding-left: 1.5em;

			&.full {
				padding-left: .5em;
			}

			&.sdata {
				padding-right: 1.5em;
			}
		}
	       */

	}

}

blockquote {
	background-color: #eee;
	background-image: linear-gradient(90deg, #eee, #fff);
	border-left: 3px solid var(--color-blue);
	padding: 2rem;

	> p {
		font-size: 1.2rem;
		font-family: var(--font-serif);
		font-style: italic;
		max-width: 40em;
		&:before {
			content: "\201c";
		}
		&:after {
			content: "\201d";
		}
	}

	p {
		margin: 0;
		padding: 0;
	}

	footer {
		font-size: small;
		p {
			line-height: 1.2;
			max-width: 50em;
			text-align: right;
			&:before {
				content: "~ ";
			}
		}
	}
}

.cont_article .toc {
	list-style: none;
	margin-left: 0;
	margin-top: .5em;
	padding-left: .25em;
	@media (--med) {
		margin-left: 1em;
	}

	li {
		line-height: 1.5;
		&:before {
			color: var(--mast2);
			content: "\00bb\0020";
			font-size: .8rem;
		}
	}

	a {
		margin-left: .25em;
	}
}

.up {
	position: sticky;
	bottom: 1em;
	max-width: unset;
	padding: 0;
	text-align: right;

	a {
		border: 1px solid #ccc;
		border-radius: 6px;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, .25);
		background: #fff;
		font-size: .8rem;
		font-weight: 600;
		padding: .25em .5em;
	}
}

.vid {
	background: #f4f3f0;
	margin-top: 0;
	position: relative;
	padding-bottom: 56.25%;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

a + img {
	display: block;
}

.vidheader {
	font-size: 1.2em;
}

.caption {
	font-style: italic;
	margin-top: .25em;
	text-align: center;
}

.tasks {

	h3 {
		font-size: 1.2em;

		&:not(:first-child) {
			border-top: 3px solid #f4f3f0;
			padding-top: 1em;
		}

	}

	h4 {
		font-size: 1.1em;
	}
}

.butt__cta {
	padding: 1em .5em;
	text-align: center;

	a {
		background: #255e81;
		border: .05em solid transparent;
		border-radius: .25rem;
		color: #fff;
		display: inline-block;
		font-size: 1.2em;
		font-weight: 600;
		padding: .5em;
		text-decoration: none;
		text-shadow: none;
		transition: all .25s;
	}

	a:focus,
	a:hover {
		background-image: unset;
		background-color: #fff;
		border-color: #255e81;
		color: #255e81;
	}
}

.disabled {
	cursor: wait;
	opacity: .25;
}

/*
.cont_article,
.cont_sidebar {
	figure {
		background: #fff;
		border: 1px solid #e4e3e0;
		border-radius: .25em;
		padding: .5em;
		text-align: center;
		margin: .5em;
		padding: 1em;
		min-width: 280px;

		figcaption {
			display: block;
			line-height: 1.2;
			font-size: .9em;
			margin: 0 auto;
			max-width: 220px;
		}

		a {
			border: 0;
			display: block;
		}

		img {
			border: 5px solid #f4f3f0;
			border-radius: .25em;
		}
	}
}
*/

/*
figure,
.vidtn {
	background: #fff;
	border: 1px solid #e4e3e0;
	border-radius: .25em;
	padding: .5em;
	text-align: center;
	margin: .5em;
	padding: 1em;

	span,
	figcaption {
		display: block;
		line-height: 1.2;
		font-size: .9em;
		margin: 0 auto;
		max-width: 240px;
	}

	img {
		border: 5px solid #f4f3f0;
		border-radius: .25em;
	}
}
*/
