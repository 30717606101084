/*
 * default styles
 */

* {
	color: inherit;
	font-family: inherit;
	line-height: inherit;
	/*
	margin: 0;
	*/
}

* + * {
	margin-top: 1em;
}

body, br, li, dt, dd, th, td, option, main, div, a, span, img, header, nav {
	margin-top: 0;
}

/*
body,
html {
	min-height: 100vh;
}
*/

html,
body {
	height: 100%;
}

html {
	box-sizing: border-box;
	/*
	filter: invert(100%);
	*/
	font-family: var(--font-main);
	scroll-behavior: smooth;
}

/*
img:not([src*=".svg"]),
video {
	filter: invert(100%);
}
*/

*,
:after,
:before {
	box-sizing: inherit;
}

svg:not(:root) {
	overflow: hidden;
}

body {
	/*
	background-image: linear-gradient(90deg, #f9f8f8 0, #fff 20%, #fff 80%, #f9f8f8);
	background-image: linear-gradient(90deg, #f9f6f3 0, #fff 20%, #fff 80%, #f9f6f3);
	*/
	/*
	background: #fff url("../s/triangles.svg") center center fixed;
	background-size: cover;
	*/
	background-color: #fff;
	color: var(--color-text);
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	/*
	overflow-y: scroll;
	max-width: var(--wrapper-max);
	*/

	@media (--med) {
		/*
		background-attachment: fixed;
		background-image: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../s/tri-bw.svg") ;
		background-position: center center;
		background-size: cover;
		mix-blend-mode: hard-light;
		*/
		background-attachment: fixed;
		/*
		background-image: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../s/tri-bw.svg") ;
		linear-gradient(90deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .1)),
		background-image: linear-gradient(135deg, rgba(225, 125, 60, .4), rgba(0, 123, 245, .6)), url("../s/triang.svg") ;
		background-image: linear-gradient(135deg, rgba(255, 125, 75, .5), rgba(255, 255, 255, .1)), url("../s/triang.svg") ;
		*/
		background-image: linear-gradient(135deg, rgba(255, 225, 175, .7), rgba(255, 255, 255, .1)), url("../s/triang.svg") ;
		background-position: center center;
		background-size: cover;
		/*
		mix-blend-mode: hard-light;
		*/
	}
	/*
	background-image: linear-gradient(135deg, rgba(255, 0, 0, .25), rgba(255, 255, 255, .35)), url("../s/triangles.svg") ;
	*/
}

h1,
h2,
h3,
h4 {
	color: var(--color-headings);
	font-weight: var(--font-norm);
}

h1 {
	font-size: 1.6rem;
	@media (--lg) {
		font-size: 2.4em;
	}
}

h2 {
	font-size: 1.3em;
	@media (--lg) {
		font-size: 1.8em;
	}
}

h3 {
	font-size: 1.1em;
	@media (--lg) {
		font-size: 1.3em;
	}
}

h4 {
	font-size: 1em;
	/*
	font-weight: var(--font-bold);
	*/
	@media (--lg) {
		font-size: 1.1em;
	}
}

hr {
	border-bottom: 1px solid #ccc;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	padding: 0;
	margin-top: 1.5em;
}

a {
	color: var(--color-links);
	text-decoration: none;
	/*
	transition: all 125ms ease-out;
	*/
	/*
	transition-delay: 0;
	*/
	&:focus,
	&:hover {
		color: var(--color-hlinks);
	}
}

h1 a,
h2 a,
h3 a,
h4 a {
	display: inline-block;
	color: var(--color-navy);
}


/*
*:focus {
	outline: 1px dotted #444;
}
*/

i,
em {
	font-style: italic;
}

b,
strong {
	font-weight: var(--font-bold);
}

.c {
	text-align: center;
}

::-moz-selection {
	color: var(--color-selection);
	background: var(--color-links);
	text-shadow: none;
}

::selection {
	color: var(--color-selection);
	background: var(--color-links);
	text-shadow: none;
}

[hidden] {
	display: none;
}

.c_wid {
	margin-left: auto;
	margin-right: auto;
	&_lg {
		max-width: var(--widthL);
	}
	&_xl {
		max-width: var(--widthXL);
	}
}

/*
a {
	&:focus {
		border: 1px dashed #888;
	}
}
*/
