body > footer {
	background: #111;
	color: #fff;
	font-size: .8em;
	margin-top: 0;
	padding: 1em;
	text-align: center;

	* {
		margin: 0;
	}

	address,
	p {
		padding: .125em 1em;
	}

	a {
		color: #aef;
		border-bottom: 1px solid transparent;
		&:focus,
		&:hover {
			border-color: #fff;
			color: #fff;
		}
	}
	@media (--lg) {
		background: rgba(0, 0, 0, .8);
	}

}

.foot_wrap {
	align-content: center;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	margin: 0 auto;
	max-width: var(--widthXL);
	justify-content: center;
	text-align: center;

	> div {
		margin: .5em;
		/*
		border: 1px solid #fff;
		*/
	}
}

.foot_cde {

	a:hover {
		border-bottom: unset;
	}
	/*
	flex: 0 0 28%;
	text-align: left;
	*/
	/*
	margin-left: auto;
	 */
}

.foot_contact {
	/*
	flex: 0 0 52%;
	text-align: center;
	*/

	li {
		padding: 0 .25em;
		display: inline-block;
		/*
		&:not(:last-child):after {
			content: "\00a0\00a0";
		}
		*/
		span {
			display: none;

			/*
			margin-left: .5em;
			*/
		}
	}

	address span {
		display: block;
		&.csz {
			display: inline-block;
		}
		&.comma:after {
			content: ", ";
		}
	}

	@media (--med) {
		address span {
			display: inline-block;
		}

		li {
			/*
			&:not(:last-child):after {
				content: " | ";
			}
			*/
			span {
				display: initial;
				margin-left: .5em;
			}
		}
	}
}
