.bttn {
	padding: .5em 1em;
	background: #fff;
	border: 1px solid rgba(0, 0, 0, .1);
	border-radius: .2em;
	box-shadow: 0 .05em .25em rgba(0, 0, 0, .15);
	color: #333;
	cursor: pointer;
	font-weight: 700;
	line-height: 1.2;
	text-align: center;
	/*
	margin: 0 auto;
	transition: .1s all;
	white-space: nowrap;
	*/
	white-space: pre-wrap;
	/*
	width: 100%;
	*/

	&:focus,
	&:hover {
		outline: .25em solid;
		outline-color: #c9d5e0;
		/*
		outline-color: #c5dee1;
		*/
		outline-offset: .25em;
	}

	&__eye {
		/*
		background: #255e81;
		background: no-repeat .5em 50% / 1em #255e81 url("../s/eye.svg");
		*/
		background: #255e81;
		color: #fff;
		font-size: 1.2em;
		line-height: 1;

		svg {
			stroke: currentColor;
			/*
			width: 1.2em;
			height: 1.2em;
			*/
			vertical-align: text-bottom;
		}

		&:focus,
		&:hover {
			background: #fff;
			color: #255e81;
		}
	}


	/*
	&__blue {
		background: #38c;
		color: #fff;
	}

	&__dkblue {
		background: var(--color-banner);
		color: #fff;
	}

	&__green {
		background: #4b9e40;
		color: #fff;
	}

	&__caution {
		background: #f6a800;
		color: #fff;
	}

	&__white {
		background: #fff;
		color: #c60;

		&:focus,
		&:hover {
			background-color: var(--color-banner);
			color: #fff;
		}
	}

	&__updt {
		background: var(--color-banner);
	}

	&__up {
		background: rgba(0, 0, 0, .00125);
	}
	*/

	/*
	&__closer {
		color: #800;
		position: fixed;
		top: 1em;
		right: 1.5em;
		&:focus,
		&:hover {
			outline-color: #800;
		}
	}
	*/
}
a {
	&.bttn {
		display: inline;
		border: none;
	}
}
