img {
	height: auto;
	max-width: 100%;
}

/* for images placed in rich text editor */
.align {
	&_left {
		float: left;
		margin: 0 1em .5em 0;
		position: relative;
		top: .5em;
		max-width: 50%;
	}
	&_right {
		float: right;
		margin: 0 0 .5em 1em;
		max-width: 50%;
	}
	&_center {
		display: block;
		margin: 1em auto;
		position: relative;
		top: .5em;
	}
}

