.cont_article ul.vidul {
	border-top: 3px solid #f4f3f0;
	display: flex;
	list-style: none;
	margin-top: 2vw;
}

.vidul {
	display: auto;
	margin: 0 auto;
	text-align: center;
	/*
	min-width: 280px;
	*/
}

.vidul {
	align-content: center;
	align-items: center;
	justify-content: center;

	* {
		color: #000;
		line-height: 1.2;
		text-align: center;
	}

	li {
		margin: .25em;
		max-width: 240px;
		text-align: center;
		width: 280px;
	}

	a {
		border: 0;
		display: block;
	}

	img {
		border: 3px solid #f4f3f0;
		border-radius: .25em;
		display: block;
		margin: 0 auto;
	}

	h2 {
		font-size: 1em;
		margin-top: 1em;
	}

	figure {
		background: #fff;
		border: 2px solid #f4f3f0;
		border-radius: .25em;
		margin: .25em;
		padding: 1em;
	}

	figcaption {
		font-size: .8em;
		margin: 0 auto;
		max-width: 220px;
	}

	[aria-current] {
		opacity: .5;
	}

	@media (--med) {
		justify-content: flex-start;
	}
}

