:root {
	--html-height: 100%;
	--html-width: 100%;
	--max-px-width: 1140;
	--color-base: #fff;
	/*
	--color-links: #5076b8;
	--color-hlinks: #5096d8;
	--color-headings: #5d6388;
	*/
	--color-hlinks: #444;
	--color-headings: #40505f;
	--color-selection: #fffefc;
	--color-text: #222;
	--color-charcoal: #444;
	--color-navy: #30415d;
	--color-footer: #4f4f4f;
	--hover-links: var(--color-headers);
	--color-blue: #52aebd;
	--color-blue2: #328e9d;
	--color-blue3: #326e7d;
	--color-blue4: #92b1cd;
	--color-blue5: #2e818f;
	--color-green: #41a51d;
	--color-orange: #e58a2d;
	--color-orange2: #e08a36;
	--color-orange3: #c08a36;
	/*
	--color-yellow: #ffcf25;
	--color-yellow: #ffd3bd;
	*/
	--color-yellow: #ffcf85;
	--color-gray: #f9f8f6;
	--color-taupe: #3e443a;
	--color-gold: #af9c61;
	--logo-blue: #164ea5;
	--color-red: #ff4350;
	--color-a11y-red: #ae141e;
	/*
	--logo-green: #41a51d;
	--mast1: var(--color-orange);
	--color-links: #40809f;
	--color-links: #2e818f;
	--color-dkorange: #a56113;
	--color-navlinks: #2e515f;
	--color-links: #0a669f;
	*/
	--color-dkorange: #864927;
	/*
	*/
	--mast1: #aa6715;
	--mast2: #255e81;
	--color-green: #008a16;
	--color-dkblue: #2e7f8f;
	--color-dkblue-sm: #2b606d;
	--color-links: #0a5c98;
	--color-navlinks: var(--mast2);
	--breadcrumb-links: #2b7583;
	--bg-selection: var(--color-links);
	/*
	--color-navlinks: #3b5c68;
	--color-navlinks: #3e616f;
	--mast1: #d77e1d;
	--mast2: var(--color-blue3);
	--mast2: #256e97;
	*/

	--mob-font-size: 90%; /* 16px */
	--base-font-size: 100%; /* 16px */
	--native-font-size: 105%;
	--heading-font-weight: normal;
	--line-height: 1.6;
	--font-norm: 400;
	--font-bold: 600;
	--font-system-sans: system-ui, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	--font-serif: "Georgia", "Times New Roman", Times, serif;
	/*
	--font-heading: "Saira", var(--font-system-sans);
	--font-heading: "Bitter", var(--font-serif);
	--font-logo: "Saira", var(--font-system-sans);
	*/
	--font-logo: "sairaregular", var(--font-system-sans);
	--font-main: "Open Sans", var(--font-system-sans);
	--font-heading: "Open Sans", var(--font-system-sans);
	--font-lora: Lora, var(--font-system-sans);
	--widthM: 37.5rem;
	--widthL: 62.5rem;
	--widthXL: 75rem;
	--wrapper-max: var(--widthXL);

	--no-topmarg {
		margin-top: 0;
	}

	--visually-hidden {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
}

@custom-media --landscape screen and (orientation: landscape);
/* small */
@custom-media --sm screen and (width >= 30em);
/*
@custom-media --sm screen and (width < 30em);
@custom-media --sm_landscape screen and (width < 30em) and (orientation: landscape);
@custom-media --sm_portrait screen and (width < 30em) and (orientation: portrait);
*/
/* small - medium */
/*
@custom-media --sm_med screen and (width >= 30em) and (width < 48em);
@custom-media --sm_med_landscape screen and (width >= 30em) and (width < 48em) and (orientation: landscape);
@custom-media --sm_med_portrait screen and (width >= 30em) and (width < 48em) and (orientation: portrait);
*/
/* medium */
@custom-media --med screen and (width >= 37.5em);
@custom-media --med_landscape screen and (width >= 37.5em) and (orientation: landscape);
@custom-media --med_portrait screen and (width >= 37.5em) and (orientation: portrait);

/* medium - large */
@custom-media --med_lg screen and (width >= 37.5em) and (width < 62.5em);
@custom-media --med_lg_landscape screen and (width >= 48em) and (width < 62.5em) and (orientation: landscape);
@custom-media --med_lg_portrait screen and (width >= 48em) and (width < 62.5em) and (orientation: portrait);
/* medium - xlarge */
@custom-media --med_xl screen and (width >= 37.5em) and (width < 80em);
@custom-media --med_xl_landscape screen and (width >= 37.5em) and (width < 80em) and (orientation: landscape);
@custom-media --med_xl_portrait screen and (width >= 37.5em) and (width < 80em) and (orientation: portrait);
/* large */
@custom-media --lg screen and (width >= 62.5em); /* 1000px */
@custom-media --lg_landscape screen and (width >= 62.5em) and (orientation: landscape);
@custom-media --lg_portrait screen and (width >= 62.5em) and (orientation: portrait);

/* lg - xl */
@custom-media --lg_xl screen and (width >= 62.5em) and (width < 68.75em);
/*
@custom-media --lg_xl_landscape screen and (width >= 62.5em) and (width < 80em) and (orientation: landscape);
@custom-media --lg_xl_portrait screen and (width >= 62.5em) and (width < 80em) and (orientation: portrait);
*/
/*
@custom-media --lg_xl screen and (width >= 62.5em) and (width < 72.9375em);
@custom-media --lg_xl_landscape screen and (width >= 62.5em) and (width < 72.9375em) and (orientation: landscape);
@custom-media --lg_xl_portrait screen and (width >= 62.5em) and (width < 72.9375em) and (orientation: portrait);
*/
/* extra large */
@custom-media --xl screen and (width >= 68.75em);
/* nav tweak */
/*
@custom-media --navbp screen and (width >= 56.25em);
*/

@custom-media --print print;
