.js {

	.hp__benefits,
	.j__ll {
		opacity: 0;
		transition: opacity 1s;
		&.loaded {
			opacity: 1;
		}
	}
}

.no-js {
	.hp__benefits {
		background-image: url("/site/templates/static/i/skyclouds.jpg");
	}
}
