.cont_sidebar {

	.sb__nav--tree {
		background: #fff;
		margin-top: 0;
		padding: .5em 1em;
		list-style-type: none;

		/*
		ul {
			list-style-type: initial;
		}

		ul ul {
			list-style-type: square;
		}
		*/
		ul ul {
			margin-left: .5rem;
		}

		ul {
			margin-top: 0;
			padding: .125rem .25rem;
		}

		li {
			padding: .125rem 0;
		}

		[aria-current="true"] {
			/*
			margin-left: -.75em;
			*/

			> a {
				color: #000;
				font-weight: 600;
				margin-left: -.75em;

				&:before {
					content: "\00BB\0020";
					font-weight: 400;
				}
			}
		}
	}

	/*
	figure {
		background: #fff;
		border: 1px solid #e4e3e0;
		border-radius: .25em;
		padding: .5em;
		text-align: center;
		margin: .5em;
		padding: 1em;
		min-width: 280px;

		figcaption {
			display: block;
			line-height: 1.2;
			font-size: .9em;
			margin: 0 auto;
			max-width: 220px;
		}

		a {
			border: 0;
			display: block;
		}

		img {
			border: 5px solid #f4f3f0;
			border-radius: .25em;
		}
	}
	*/
	section {
		margin-top: 0;
	}
}

.sbar {

	&__def {
		padding: 1em .5em 0;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		a {
			border: none;
			color: #555;
			display: block;
			font-weight: 500;
			line-height: 1.4;
			padding: .5em;
			&:active,
			&:focus,
			&:hover {
				color: #000;
			}
		}

		/*
		li {
			background-color: #fff;
			background-position: .5rem .5rem;
			background-repeat: no-repeat;
			background-size: 1.5rem;
			border: 1px solid #ddd;
			border-radius: .25em;
			margin: 0 0 .5em;
			padding-left: 2rem;

			&:first-child {
				background-image: url("../s/download.svg");
			}

			&:nth-child(2n) {
				background-image: url("../s/help.svg");
			}

			&:nth-child(3n) {
				background-image: url("../s/mail.svg");
			}

			&:last-child {
				background-image: url("../s/credit.svg");
			}
		}
		*/

		/*
		a {
			background-color: #fff;
			background-position: .5rem .5rem;
			background-repeat: no-repeat;
			background-size: 1.5rem;
			border: 1px solid #ddd;
			border-radius: .25em;
			margin: 0 0 .5em;
			padding-left: 2.5rem;

			&._dl {
				background-image: url("../s/download.svg");
			}

			&._faq {
				background-image: url("../s/help.svg");
			}

			&._signup {
				background-image: url("../s/mail.svg");
			}

			&._fee {
				background-image: url("../s/credit.svg");
			}

			&._book {
				background-image: url("../s/book.svg");
			}
		}
		*/

		a {
			background-color: #fff;
			background-position: .5rem .5rem;
			background-repeat: no-repeat;
			background-size: 1.5rem;
			border: 1px solid #ddd;
			border-radius: .25em;
			margin: 0 0 .5em;
			padding-left: 2.5rem;
		}

		/*
		li {

			&:nth-child(1) a {
				background-image: url("../s/download.svg");
			}

			&:nth-child(2) a {
				background-image: url("../s/help.svg");
			}

			&:nth-child(3) a {
				background-image: url("../s/book.svg");
			}

			&:nth-child(4) a {
				background-image: url("../s/credit.svg");
			}

			&:nth-child(5) a {
				background-image: url("../s/mail.svg");
			}

			&:nth-child(6) a {
				background-image: url("../s/new.svg");
			}
		}
		*/

	}
}
