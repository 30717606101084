/*
.search {
	background: #fff;
	border: 1px solid #ddd;
	border-radius: .25em;
	margin: 0 .5rem .125rem;
	margin: 0 .5rem;
	padding: .5em;
	position: relative;

	input {
		background: #fff;
		border: 1px solid #ccc;
		color: #000;
		flex: 1;
		margin: 0;
		padding: .25em;
		position: relative;
		z-index: 2;
		&:focus {
			background: #fff;
			border-color: #111;
		}
	}

	p {
		padding: 0;
		margin: 0;
	}

	.flx__inpts {
		align-content: center;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: stretch;
		padding-top: .25em;
	}

	button {
		background: #0a680a;
		border: 1px solid #0a680a;
		color: #fff;
		font-size: 1rem;
		font-weight: 600;
		line-height: inherit;
		margin: 0;
		padding: .25em .5em;

		&.toggle {
			background: transparent;
			border: none;
			color: #333;

			svg {
				fill: hsl(0, 80%, 100%);
				fill: #666;
				height: 1.2em;
				width: 1.2em;
			}
		}

		&:focus {
			border-color: #000;
		}
	}

}
*/

.search {
	margin: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: -200%;
	transition: opacity .25s ease-out 0s;
	width: 100%;
	/*
	width: 100%;
	width: calc(100% - 2em);
	width: 100%;
	*/
	z-index: 1;

	&.show {
		opacity: 1;
		right: 0;
		top: auto;
		bottom: 0;
		z-index: 10;
		position: fixed;
	}

	&__toggle {
		background: #fff;
		border: 1px dotted #fff;
		cursor: pointer;
		margin-top: 0;
		position: absolute;
		right: 1em;
		top: 1em;

		&:hover,
		&:focus {
			svg {
				fill: #333;
				outline: none;
			}
		}

		&:focus {
			border-color: #000;
		}

		svg {
			fill: var(--mast2);
			height: 1.5rem;
			transition: fill .25s ease-out 0s;
			width: 1.5rem;

			@media (--lg) {
				height: 1.75rem;
				padding: .25rem;
				width: 1.75rem;
			}
		}

		&[aria-expanded="true"] {
			svg {
				fill: #999;
			}
		}

		@media (--lg) {
			padding-top: .5rem;
			position: relative;
			right: unset;
			top: unset;
			bottom: unset;
		}

	}

	/*
	&__toggle.show + .search {
		top: 4.5rem;
	}
	*/

	input {
		background: #fff;
		border: 1px solid #999;
		color: #000;
		flex: 1;
		margin: 0;
		padding: .5em;
		position: relative;
		z-index: 2;
		&:focus {
			background: #fff;
			border-color: #111;
		}
		@media (--lg) {
			max-width: 400px;
		}
	}

	.flx__inpts {
		align-content: center;
		align-items: center;
		background: hsla(0, 0%, 20%, .75);
		display: flex;
		flex-direction: row;
		justify-content: stretch;
		margin-top: 0;
		padding: 1rem;
		text-align: center;

		@media (--lg) {
			background: hsla(0, 40%, 100%, .5);
			background: transparent;
			justify-content: space-between;
			padding: .5rem;
		}
	}

	button {
		background: #0a680a;
		border: 1px solid #0a680a;
		color: #fff;
		font-size: 1rem;
		font-weight: 600;
		line-height: inherit;
		margin: 0;
		padding: .5em;
	}

	@media (--lg) {
		width: unset;
		&.show {
			position: absolute;
			top: 3.625em;
			/*
			top: 4.25rem;
			top: 3.625em;
			*/
		}
	}
}

.srch {
	color: #fff;
}

/*
.harea {
	position: relative;

	.flx__inpts {
		align-content: center;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: stretch;
	}

	input {
		background: #fff;
		border: 1px solid #ccc;
		flex: 1;
		margin: 0;
		padding: .5em;
		position: relative;
		z-index: 2;
		&:focus {
			background: #fff;
			border-color: #111;
		}
	}

	label {

		&:after {
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	p {
		padding: 0;
		margin: 0;
	}

	.inst {
		font-size: 75%;
	}

	button {
		background: #0a680a;
		border: 1px solid #0a680a;
		color: #fff;
		font-size: 1rem;
		line-height: inherit;
		margin: 0;
		padding: .5em 1em;

		&:focus {
			border-color: #000;
		}
	}
}
*/
